import React, { Component } from 'react';
import pic1 from '../images/pic_1-1.jpg';
import pic2 from '../images/pic_2-1.jpg';
import pic3 from '../images/pic_3-1.jpg';

class Technical extends Component {
    render() {
        let styleImg = { width : '50%'}
        return (
            <div className="technical" id="technical">
                <div className="container">
                    <div className="text-center">
                        <h2>MENCIPTAKAN PENDIDIKAN VOKASI YANG PROFESIONAL</h2>
                        <p>
                            Dunia Industri memerlukan ketrampilan tingkat tinggi. 
                            Tetapi apabila Anda membandingkan di sekitar Anda, 
                            Anda akan menjumpai 90 % pendidikan tinggi didominasi 
                            pendidikan akademis yang menekankan pada teori. 
                            Politeknik Krakatau mengajarkan praktek yang berbasis pada teori, 
                            sehingga mahasiswa POLKA memiliki keahlian terapan, 
                            dan siap untuk kerja - kerja - kerja.
                        </p>
                    </div>
                </div>
                <div className="container text-center">
                    <div className="row">
                        <div className=" col-xs-12 col-sm-4 col-md-4 col-lg-4 skillsArea">
                            <img src={pic1} alt="" className="rounded-circle" style={styleImg}/>
                            <h4>TEKNIK MESIN (D3)</h4>
                            <p>Sasaran utama bidang keahlian ini adalah menjadi ahli madya profesional di bidang teknik mesin industri</p>
                        </div>
                        <div className=" col-xs-12 col-sm-4 col-md-4 col-lg-4 skillsArea">
                            <img src={pic2} alt="" className="rounded-circle" style={styleImg} />
                            <h4>TEKNIK LISTRIK (D3)</h4>
                            <p>Teknik Listrik adalah salah satu bidang ilmu teknik mengenai aplikasi listrik untuk memenuhi kebutuhan daya listrik dalam skala besar seperti pada industri dan mesin-mesin.</p>
                        </div>
                        <div className=" col-xs-12 col-sm-4 col-md-4 col-lg-4 skillsArea">
                            <img src={pic3} alt="" className="rounded-circle" style={styleImg} />
                            <h4>TEKNIK ELEKTRONIKA (D3)</h4>
                            <p>Teknik Elektronika adalah bidang teknik yang mempelajari tentang komponen listrik danperalatan-peralatan semi konduktor.</p>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Technical;