import { SIMPAN_BUKU, SIMPAN_TODO } from '../actions/index'

const INITIAL_STATE = { screen: '', history: [] }

const buku = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SIMPAN_BUKU:
            return {
                ...state,
                screen:  Number(state.screen) + action.value,
                history: state.history.concat(`${state.screen}`)
            }
        case SIMPAN_TODO:
            return {
                ...state,
                history : [...state.history, action.value],
                screen: action.value
            }
        default:
            return state
    }
}
export default buku