import React, { Component } from 'react';
import logo from '../images/logo.png';
import header from '../images/header-min.png';
import { connect } from 'react-redux';

class Banner extends Component {
    render() {
        let style = {
            height : '100%'
        }

        let imgStyle = {
            width : '100%'
        }

        let logoStyle = {
            width : '15%'
        }

        return (
            <div>
                <div className="banner row" id="banner">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadd" style={style}>
                        <div className="bannerPart"> 
                            <img src={header} alt="header" style={imgStyle} id="imgBanner"/>
                        </div>
                    </div>
                </div>
                
                <div className="bannerText container">
                    <img src={logo} alt="logo" style={logoStyle} />
                    <h1>{ this.props.data.banner.title }</h1>
                </div>
            </div>
        );
    }
}

function mapStateToProps(params) {    
    return {
        data: params.Polka
    }
}

export default connect(mapStateToProps, null)(Banner);