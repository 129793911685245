import React, { Component } from 'react';
import NavigasiBar from '../containers/Navbar';
import Banner from '../containers/Banner';

class Header extends Component {
    render() {
        return (
            <header>
                <NavigasiBar />
                <Banner />
            </header>
        );
    }
}


export default Header;
