import React, { Component } from 'react';

class Education extends Component {
    render() {
        return (
            <div className="education" id="education">
                <div className="container">
                    <div className="heading">
                        <h2>METODE PEMBELAJARAN</h2>
                        <p>Polka menjunjung tinggi ketrampilan lulusannya. Lebih banyak praktek daripada teori.
                            Pembelajaran dilakukan secara clasical dan secara bertahap, akan melakukan
                            cara-cara baru memanfaatkan kecanggihan teknologi untuk meningkatkan efektifitas
                            pembelajaran.</p>
                    </div>
                    <div className="row workDetails">
                        <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2">
                            <div className="workYear">
                                <i className="fa fa-whatsapp fa-lg"></i> Whatsapp
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-9 col-md-10 col-lg-10 rightArea">
                            <div className="arrowpart"></div>
                            <div className="exCon">
                                <h4>Teknologi Media Sosial</h4>
                                <h5>(whatsapp dan facebook)</h5>
                                <p>Untuk diskusi tugas-tugas dan keilmuan dengan dosen pengampu.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row workDetails">
                        <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2">
                            <div className="workYear"><i className="fa fa-video-camera fa-lg"></i> Video Conference</div>
                        </div>
                        <div className="col-xs-12 col-sm-9 col-md-10 col-lg-10 rightArea">
                            <div className="arrowpart"></div>
                            <div className="exCon">
                                <h4>Video Conference</h4>
                                <h5>Untuk Studium General dan perkuliahan dengan nara sumber dari manca negara.</h5>
                                <p>Saat ini telah terhimpun beberapa dosen praktisi industri dari Dhoha Qatar dan Malaysia, 
                                    insyaAllah menyusul dari belahan bumi lainnya.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row workDetails">
                        <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2">
                            <div className="workYear"><i className="fa fa-youtube fa-lg"></i> Youtube</div>
                        </div>
                        <div className="col-xs-12 col-sm-9 col-md-10 col-lg-10 rightArea">
                            <div className="arrowpart"></div>
                            <div className="exCon">
                                <h4>Youtube</h4>
                                <p>Secara bertahap, akan dibuat bahan ajar yang diupload di Youtube untuk pengulangan materi apabila
                                    penjelasan dalam kelas dirasa masih belum bisa difahami.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Education;