import React from 'react';
import {render} from 'react-dom';
import {createStore} from 'redux';
import {Provider} from 'react-redux';
import rootReducer from './reducers/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/style.css';
import './fonts/fontawesome-webfont.eot';
import './css/font-awesome.min.css';
import App from './App';

const store = createStore(rootReducer)

render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
)