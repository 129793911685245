import React, { Component } from 'react';

class Aplication extends Component {
    render() {
        let style = {
            marginBottom : '10px'
        }
        return (
            <div className="education" id="aplication">
                <div className="container">

                    <div className="heading">
                        <h2>DAFTAR APLIKASI</h2>
                    </div>

                    <div className="row">
                        <div className="col-xs-5 col-sm-3 col-md-3 col-lg-3" align="center">
                            <div className="workYear" style={style}>
                                <i class="fa fa-graduation-cap fa-lg"></i>
                                <a href="http://siakad.polka.ac.id">SIAKAD</a>
                            </div>
                        </div>
                        <div className="col-xs-5 col-sm-3 col-md-3 col-lg-3" align="center">
                            <div className="workYear" style={style}>
                                <i className="fa fa-archive fa-lg"></i>
                                <a href="http://122408300033.ip-dynamic.com:8070">SISTER</a>
                            </div>
                        </div>
                        <div className="col-xs-5 col-sm-3 col-md-3 col-lg-3" align="center">
                            <div className="workYear" style={style}>
                                <i className="fa fa-archive fa-lg"></i>
                                <a href="http://polka.ac.id/e_pengajuan/">E-Pengajuan</a>
                            </div>
                        </div>

                        <div className="col-xs-5 col-sm-3 col-md-3 col-lg-3" align="center">
                            <div className="workYear" style={style}>
                                <i className="fa fa-book fa-lg"></i>
                                <a href="http://books.polka.ac.id/" >E-LIB</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Aplication;