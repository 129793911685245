import React, { Component } from 'react';

class Experience extends Component {
    render() {
        return (

            <div className="exprience" id="exprience">
                <div className="container">
                    <div className="heading">
                        <h2>LABORATORIUM</h2>
                    </div>
                    <div className="row workDetails">
                        <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2">
                            <div className="workYear">TEKNIK<br />MESIN</div>
                        </div>
                        <div className="col-xs-12 col-sm-9 col-md-10 col-lg-10 rightArea">
                            <div className="arrowpart"></div>
                            <div className="exCon">
                                <h5>
                                    <ul>
                                        <li>Laboratorium Gambar</li>
                                        <li>Laboratorium Fisika</li>
                                        <li>Laboratorium Metrologi Industri & Kontrol Kualitas</li>
                                        <li>Laboratorium Material Teknik</li>
                                        <li>Laboratorium Proses Produksi</li>
                                        <li>Bengkel Mekanik</li>
                                    </ul>
                                </h5 >
                            </div >
                        </div >
                    </div >
                    <div className="row workDetails">
                        <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2">
                            <div className="workYear">
                                TEKNIK<br />
                                LISTRIK
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-9 col-md-10 col-lg-10 rightArea">
                            <div className="arrowpart"></div>
                            <div className="exCon">
                                <h5>
                                    <ul>
                                        <li>Laboratorium Mesin-mesin Listri</li>
                                        <li>Laboratorium Otomasi Industri</li>
                                        <li>Laboratorium Sistem Tenaga Listrik</li>
                                        <li>Laboratorium Pengukuran Dasar</li>
                                        <li>Laboratorium Elektronika Digital dan Analog</li>
                                        <li>Laboratorium Tegangan Menengah</li>
                                        <li>Bengkel Instalasi Tenaga LIstrik</li>
                                    </ul>
                                </h5 >
                            </div >
                        </div >
                    </div >
                    <div className="row workDetails">
                        <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2">
                            <div className="workYear">TEKNIK<br />
                                ELEKTRONIKA</div>
                            </div>
                            <div className="col-xs-12 col-sm-9 col-md-10 col-lg-10 rightArea">
                                <div className="arrowpart"></div>
                                <div className="exCon">
                                    <h5>
                                        <ul>
                                            <li>Laboratorium Elektronika Analog & Digital</li>
                                            <li>Laboratorium Kontrol Otomatis</li>
                                            <li>Laboratorium Instrumentasi Industri</li>
                                            <li>Laboratorium Pemeliharaan dan Perbaikan</li>
                                            <li>Laboratorium Produksi Elektronika</li>
                                            <li>Laboratorium Mekatronika dan Robotika</li>
                                            <li>Laboratorium Pemograman Komputer</li>
                                            <li>Laboratorium Pengolahan Sinyal</li>
                                            <li>Laboratorium Dasar Sistem Telekomunikasi</li>
                                            <li>Laboratorium Programmable Logic Controll(PLC)</li>
                                        </ul>
                                    </h5 >
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
        );
    }
}

export default Experience;