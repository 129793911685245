const INITIAL_STATE = { 
    banner : {
        title : 'POLITEKNIK KRAKATAU'
    },
    aboutMe : {
        heading : 'Politeknik Krakatau',
        p1 : 'Politeknik adalah pendidikan tinggi kejuruan belajar tak sekedar teori, kemampuan praktek lebih diutamakan',
        p2 : 'Lahir dari rahim Pusat Pendidikan dan Latihan (Pusdiklat) PT. Krakatau Steel , Politeknik Krakatau (POLKA) adalah Perguruan Tinggi Teknologi yang berbasis pada penguasaan keahlian terapan (tidak sebatas teori, tetapi praktek nyata). Oleh karena itu pengajaran POLKA menekankan pada praktek pada laboratorium dan bengkel dan bengkel-bengkel yang dimiliki.',
        p3 : 'Polka memiliki peralatan Laboratorium untuk memfasilitasi peningkatan kompetensi mahasiswa ,sehingga polka hadir sebagai politeknik yang unggul dalam ilmu pengetahuan terapan yang mencetak lulusan yang bermoral, berkarakter positif dan menjadi rujukan penelitian di bidang teknik untuk meningkatkan daya saing bangsa.',
    }
}

const Polka = (state = INITIAL_STATE, action) => {
    return state
}
export default Polka