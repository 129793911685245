export const SIMPAN_BUKU = 'SIMPAN_BUKU'
export const SIMPAN_TODO = 'SIMPAN_TODO'

export function simpanBuku(value) {
    console.log('BUKU TERSIMPAN : ', value)
    return {
        type: SIMPAN_BUKU,
        value
    }
}

export function simpanTodo(value) {
    return {
        type: SIMPAN_TODO,
        value
    }
}