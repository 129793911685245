import React, { Component } from 'react';

class Contact extends Component {
    render() {
        return (
            <div>
                <div className="contact" id="contact">
                    <div className="row mapArea">
                        <iframe frameborder="0" scrolling="no" marginheight="0" marginwidth="0" title="googlemap"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d247.99244231150502!2d106.0499834206876!3d-6.011337371131689!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x647db44d8553c603!2sPoliteknik+Krakatau+%26+LP3K!5e0!3m2!1sid!2sid!4v1509206778424"></iframe>
                    </div>
                </div>
                <div className="contactDetails">
                    <div className="container">
                        <div className="col-xs-12 col-sm-3 col-md-4 col-lg-4">
                            <h4>DETAIL KONTAK</h4>
                            <p>
                                <i className="fa fa-map-marker fa-lg"></i>
                                Komplek Bonakarta Blok B07 Lantai 3,<br />
                                <span style={{marginLeft:'30px'}}>Jl. S.A Tirtayasa No. 49, Kota Cilegon</span>
                            </p>
                            <p> <i className="fa fa-mobile fa-lg"></i> (0254) 385-268, 388-630</p>
                            <p> <i className="fa fa-envelope-o "></i> <a href="mailto:info@polka.ac.id">info@polka.ac.id</a></p>
                            <p> <i className="fa fa-link "></i> <span>www.polka.ac.id</span></p>
                            <p> <i className="fa fa-mobile fa-lg"></i> <span>WA 081311697098</span></p>
                            <p> <i className="fa fa-facebook fa-lg"></i><a href="https://www.facebook.com/politeknikrakatau">Facebook</a>
                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-9 col-md-8 col-lg-8 conForm">
                            <h4>PESAN SINGKAT !</h4>
                            <div id="message"></div>
                            <form method="post" action="php/contact.php" name="cform" id="cform">
                                <input name="name" id="name" type="text" className="col-xs-12 col-sm-6 col-md-6 col-lg-6"
                                    placeholder="Your name..." required />
                                <input name="email" id="email" type="email" className=" col-xs-12 col-sm-6 col-md-6 col-lg-6 noMarr"
                                    placeholder="Your email..." required />
                                <textarea name="comments" id="comments" cols="" rows="" className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                                    placeholder="Your message..." required></textarea>
                                <input type="submit" id="submit" name="send" className="submitBnt" value="Send message" required />
                                <div id="simple-msg"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;