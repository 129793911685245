import React from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';

class NavigasiBar extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
        this.handleScroll = this.handleScroll.bind(this);
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    handleScroll() {
        this.setState({ scroll: window.scrollY });
    }

    componentDidMount() {
        const el = document.querySelector('nav');
        this.setState({ top: el.offsetTop, height: el.offsetHeight });
        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate() {
        this.state.scroll > this.state.top ?
            document.body.style.paddingTop = `${this.state.height}px` :
            document.body.style.paddingTop = 0;
    }

    render() {
        return (
            <div>
                <nav>
                    <Navbar color="dark" dark expand="md" className={this.state.scroll > this.state.top ? "fixed-top" : ""} >
                        <NavbarBrand href="/"></NavbarBrand>
                        <NavbarToggler onClick={this.toggle} />
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className="ml-auto" navbar>

                                <NavItem>
                                    <NavLink href="#banner">BRANDA</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#aboutme">TENTANG</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#technical">JURUSAN</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#exprience">LABORATORIUM</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#education">METODE PEMBELAJARAN</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#protfolio">BROSUR</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#aplication">APLIKASI</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#contact">KONTAK</NavLink>
                                </NavItem>

                            </Nav>
                        </Collapse>
                    </Navbar>
                </nav>
            </div>
        );
    }
}

export default NavigasiBar;