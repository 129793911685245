import React, { Component } from 'react';
import AboutMe from '../containers/AboutMe';
import Technical from '../containers/Technical';
import Experience from '../containers/Experience';
import Education from '../containers/Education';
import Protfolio from '../containers/Protfolio';
import Aplication from '../containers/Aplication';
import Contact from '../containers/Contact';

class Body extends Component {
    render() {
        return (
            <div>
                <AboutMe />
                <Technical />
                <Experience />
                <Education />
                <Protfolio />
                <Aplication />
                <Contact />
            </div>
        );
    }
}


export default Body;
