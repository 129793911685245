import React, { Component } from 'react';
import { connect } from 'react-redux';
import pic1 from '../images/pic_1-1.jpg';

class AboutMe extends Component {
    render() {
        let colorYellowStyle = {
            color : 'yellow'
        }
        
        return (
            <div className="aboutme" id="aboutme">
                <div className="container">
                    <div className="heading">
                        <h2>{ this.props.data.aboutMe.heading.slice(0, 10) } 
                            <b style={colorYellowStyle}>{ this.props.data.aboutMe.heading.slice(10, 19) }</b>
                        </h2>
                        <p>{ this.props.data.aboutMe.p1 }</p>
                    </div>
                    <div className="row">
                        <div className=" col-xs-12 col-sm-12 col-md-7 col-lg-7">
                            <p>{ this.props.data.aboutMe.p2 }</p>
                            <p>{ this.props.data.aboutMe.p3 }</p>
                        </div>
                        <div className=" col-xs-12 col-sm-12 col-md-4 col-lg-4 col-md-offset-1 col-lg-offset-1 proPic"> 
                            <img src={pic1} alt="pic1" className="rounded-circle" style={{ width : '70%' }}/> 
                        </div> 
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(params) {    
    return {
        data: params.Polka
    }
}

export default connect(mapStateToProps, null)(AboutMe);