import React, { Component } from 'react';
import Header from './components/Header';
import Body from './components/Body';
import { Col } from 'reactstrap';

class App extends Component {
  render() {
    let style = {
      margin : 0,
      padding : 0
    }
    return (
      <Col style={style}>
        <Header />
        <Body />
      </Col>
    );
  }
}


export default App;
